import './scripts/common';

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

// Todo: Add page specific JS here e.g:
import * as lightbox from '@elements/lightbox';
lightbox.init({
        // plugins: ['zoom', 'video'],
        // zoom: true,
        // video: true,
        mobileSettings: {
            controls: true
        }
    }
);

import * as lazyIframe from '@elements/lazy-iframe';
lazyIframe.init({
    // Options
});

import * as dateInput from "@elements/date-input";
dateInput.init();

import * as datepicker from "@elements/datepicker";
datepicker.init();

import * as toggleForm from "./scripts/toggle-form";
toggleForm.init();
