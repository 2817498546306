import {onFind} from "@elements/init-modules-in-scope";
import {removeClass,addClass,on,findIn,findAllIn,closest} from '@elements/dom-utils';

const selectors = {
    base: '.js-toggle-form',
    select: '.js-toggle-form__select',
    checkbox: '.js-toggle-form__checkbox',
    radio: '.js-toggle-form__radio',
    form: '.js-toggle-form__form',
    radioForm: '.js-toggle-form__radio-form'
};

export function init() {
    onFind(selectors.base, function (baseElement) {
        let checkbox = findIn(selectors.checkbox, baseElement);
        let select = findIn(selectors.select, baseElement);
        let form = findIn(selectors.form, baseElement);
        let radio = findAllIn(selectors.radio, baseElement);

        if (select){
            //for commissioning form
            if (select.hasAttribute("data-block")){
                on('change', function() {
                    let ids = select.getAttribute("data-block").split(",");
                    let blockId = select.value
                    let block = document.getElementById("#" + blockId)

                    ids.forEach((id) => {
                        if (document.getElementById("#" + id)){
                            addClass('d-none', document.getElementById("#" + id));
                        }
                        if (id === blockId && block){
                            removeClass('d-none', block);
                        }
                    });
                }, select);
            }else{
                on('change', function() {
                    if(select.value === 'yes') {
                        removeClass('d-none', form);
                    } else {
                        addClass('d-none', form);
                    }
                }, select);
            }
        }

        if (checkbox){
            on('change', function() {
                if(checkbox.getElementsByTagName('input')[0].checked) {
                    removeClass('d-none', form);
                } else {
                    addClass('d-none', form);
                }
            }, checkbox);
        }

        if (radio){
            radio.forEach((btn) => {
                on('change', function() {
                    let group = closest(selectors.radioForm, btn);
                    let form = findIn(selectors.form, group);

                    if (form){
                        if(btn.getElementsByTagName('input')[0].checked) {
                            removeClass('d-none', form);
                        }else{
                            addClass('d-none', form);
                        }
                    }

                }, baseElement);
            })
        }
    });
}

